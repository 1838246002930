<template>
  <carrusel-module-component />
</template>

<script>
import CarruselModuleComponent from "@/components/admin/modules/CarruselModuleComponent";

export default {
  name: "CarruselModule",
  title: "Carrusel | Baja California Health Tourism",
  components: { CarruselModuleComponent },
};
</script>

<style scoped></style>
